.digital-waiver-container {
  .search-dw-field {
    background-color: $brown !important;
    border: 1px solid lighten($primary, 20) !important;
    color: $white !important;
    border-radius: 40px !important;
  }

  .form-label {
    color: $white !important;
  }

  .form-check-input {
    background-color: darken($primary, 20) !important;
    width: 30px;
    height: 30px;
    &:checked {
      background-color: $primary !important;
    }
  }

  .searchable-select-input {
    color: $white !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $wnt-gray-500 !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $wnt-gray-500 !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $wnt-gray-500 !important;
  }

  input {
    color: $white !important;
    border-color: transparent;
    &:disabled {
      color: $wnt-gray-500 !important;
    }
  }

  .delegated-form {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white !important;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $white !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $white !important;
    }
  }

  select {
    color: $white !important;
    &:disabled {
      color: $wnt-gray-500 !important;
      background-color: transparent;
    }
  }
}
