.table-action-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: unset;
  & > *:not(:last-child) {
    @extend .me-2;
    display: inline-block;
  }
}

.custom-table-row {
  padding-left: 1rem;
}

.table-right-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  & > *:not(:last-child) {
    @extend .me-3;
  }
  & > * {
    @extend .mb-3;
  }
}

.table-left-toolbar {
  display: flex;
  flex-wrap: wrap;
  & > *:not(:last-child) {
    @extend .me-3;
  }
  & > * {
    @extend .mb-3;
  }
}

.filter-table-search-input {
  max-width: 20rem;
  width: 100%;
}

.filter-table-input-container {
  display: flex;
  flex-wrap: nowrap;
}

.table-drawer-options-checkbox-container {
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex-basis: 50%;
  }
}

.table-checkbox-cell {
  width: 1rem;
}

.table-action-header {
  @extend .pt-8;
  @extend .px-8;

  display: flex;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  .table-action-header-left {
    order: 1;
    flex-grow: 1;
    align-items: flex-start;
    @include media-breakpoint-down(sm) {
      max-width: calc(100% - 2.5rem);
    }
  }
  .table-action-header-right {
    order: 2;
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
    align-items: flex-start;
    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      width: 100%;
    }
    & > *:not(:last-child) {
      @extend .me-3;
    }
  }
  .table-action-header-option {
    order: 3;
    margin-right: -1.5rem;
    @include media-breakpoint-down(sm) {
      order: 1;
    }
  }
}

.table.table-highlighted {
  tbody tr {
    &:hover {
      background-color: rgba($light, 0.5);
    }
  }
}
