.wallet-border-color{
    border: 1px solid #464545;
}

.height-100px{
    height: 100px;
}

.height-150px{
    height: 150px;
}

.qr-img-size{
    width: 200px;
    height: 200px;
}

.event-img-size{
    width: 270px;
    height: 25px;
}

.wallet-day-text{
    font-size: 30px;
}

.fs-20px{
    font-size: 20px;
}
.fs-18px{
    font-size: 18px;
}
.fs-15px{
    font-size: 15px;
}

.apple-card{
    width: 350px !important;
    min-height: 550px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    position: relative !important;
    // clip-path: polygon(48% 2%, 52% 2%, 55% 0, 100% 0, 100% 100%, 0 100%, 0 0, 45% 0) !important;
    // clip-path: polygon(45% 2%, 55% 2%, 60% 0, 100% 0, 100% 100%, 0 100%, 0 0, 40% 0) !important;
    clip-path:
polygon(60% 1.5%, 65% 0, 100% 0, 100% 100%, 0 100%, 0 0, 35% 0, 40% 1.5%) !important;
    max-height: 800px !important;
}

.apple-title-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.apple-logo {
    // height: 40px;
    max-width: 115px;
    min-width: 40px;
    width: auto;
    position: absolute;
    top: 42%;
    transform: translateY(-50%);
}

.apple-banner {
    width: 100%;
    max-height: 150px;
    min-height: 50px;
}
.apple-banner-container{
    max-height: 310px;   
}
.bfield-hz-divider{
    color: black !important
}
.apple-form-container {
    max-height: 100vh !important;
    overflow-y: auto !important;
}

//for android design below if necessary

.goolge-qr-img-size {
    width: 140px;
    height: 140px;
}

.horizontal-divider-no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
}

.google-divider {
    margin-left: -20px !important;
    margin-right: -20px !important;
    color: #000
}
.google-divider-light {
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.google-title {
    font-size: '16px';
    background: 'transparent';
    color: '#fff';
    border: 'none';
    border-bottom: '1px solid #fff';
    outline: 'none';
}

.google-card {
    width: 350px !important;
    height: 550px !important;
    border-radius: 20px !important;
    padding: 20px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    position: relative !important;
}
.google-card-back {
    width: 350px !important;
    min-height: 550px;
    max-height: 550px !important;
    border-radius: 20px !important;
    padding: 20px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    position: relative !important;
    background: #000000;
    overflow-y: auto; 
}
.google-banner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 20px 20px;
}
.google-title-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.google-header-container {
    margin-top: 20px;
}

.google-display-text-container {
    margin-top: 20px;
}

.google-display-text-start,
.google-display-text-middle,
.google-display-text-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.google-display-text-start {
    align-items: flex-start; 
    text-align: left;
}

.google-display-text-middle {
    align-items: center;
    text-align: center;
}

.google-display-text-end {
    align-items: flex-end; 
    text-align: right;
}

.google-display-text p {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
  }

.google-default-logo {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #201e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
}

.google-logo {
    height: 40px;
    border-radius: 50%;
}

.google-back-footer {
    font-size: 10px;
}

.editable-field {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.editable-field-input {
    font-size: 13px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    color: #ffffff;
    margin-bottom: 8px,
}

.editable-field-extra {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    color: #ffffff;
    font-weight: 600;
}
.editable-field-dt {
    margin-bottom: 8px;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.wallet-container {
    padding: 20px;
  }
  
  .google-wallet-preview .google-card,
  .google-wallet-form .google-card {
    width: 100%; 
    max-width: 330px; 
    margin: 0 auto; 
  }
  
  @media (min-width: 768px) {
    .google-wallet-preview .google-card,
    .google-wallet-form .google-card {
      margin: 0 auto; 
    }
  }
  
  @media (min-width: 990px) and (max-width: 1550px) {
    .google-wallet-preview .google-card,
    .google-wallet-form .google-card {
      width: auto; 
    }
    
    .wallet-container {
      padding: 40px; 
    }
    

    .d-flex.flex-column.flex-lg-row .col-lg-6 {
      flex: 0 0 100%; 
    }
  }
  
  @media (min-width: 1550px) {
    .d-flex.flex-column.flex-lg-row .col-lg-6 {
      flex: 0 0 50%;  
    }
    
    .google-wallet-preview .google-card,
    .google-wallet-form .google-card {
      width: 350px; 
    }
  }

  @media (max-width: 767px) {
     .google-card-container {
        justify-content: center !important;
    }
  }
  