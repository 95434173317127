.extra-input-item {
    @extend .mb-5;
  
    .extra-input-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
  
      @media (max-width: 768px) {
        justify-content: space-between;
      }
    }
  }
  
  .extra-input-item-input-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  
    & > * {
      flex: 1 1 auto;
  
      &:not(:last-child) {
        @extend .me-3;
      }
    }
  
    &__select-input,
    &__text-input {
      flex-grow: 1;
      min-width: 150px;
    }
  
    &__order-input {
      width: 5rem;
      flex-grow: 0;
      margin-bottom: 16px !important;
    }
  
    &__check-input {
      margin-top: 15px !important;
    }
  
    &__filter-input {
      margin-bottom: 16px !important;
      flex-grow: 1;
      min-width: 185px;
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
      &__select-input,
      &__text-input,
      &__filter-input {
        width: 100%;
        margin-bottom: 0.5rem;
      }
      &__order-input {
        width: 100%;
      }
    }
  }
  