.btn-dark-primary-dw {
  background-color: darken($primary, 27) !important;
  color: white !important;
  &:hover {
    background-color: darken($primary, 30) !important;
    &:not(.btn-active) {
      background-color: darken($primary, 27) !important;
    }
  }
}

.btn-select-all-dw {
  background-color: darken($secondary, 10) !important;
  color: white !important;
  &.active {
    opacity: 1;
    background-color: darken($primary, 15) !important;
  }
}

// button-dark
