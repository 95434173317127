@font-face {
  font-family: 'f1-bold';
  src: url('/_metronic/assets/fonts/f1-display-bold.ttf');
}

@font-face {
  font-family: 'f1-regular';
  src: url('/_metronic/assets/fonts/f1-display-regular.ttf');
}

@font-face {
  font-family: 'din-pro-condensed-black';
  src: url('/_metronic/assets/fonts/din-pro-condensed-black-900.otf');
}

@font-face {
  font-family: 'din-next-lta-arabic-regular';
  src: url('/_metronic/assets/fonts/din-next-lta-arabic-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'din-next-lta-arabic-heavy';
  src: url('/_metronic/assets/fonts/din-next-lta-arabic-heavy.ttf') format('truetype'),
    url('/_metronic/assets/fonts/din-next-lta-arabic-heavy.otf') format('opentype'),
    url('/_metronic/assets/fonts/din-next-lta-arabic-heavy.woff') format('woff'),
    url('/_metronic/assets/fonts/din-next-lta-arabic-heavy.woff2') format('woff2'),
    url('/_metronic/assets/fonts/din-next-lta-arabic-heavy.eot') format('eot');
}

.ff-f1 {
  &-regular {
    font-family: 'f1-regular';
  }

  &-bold {
    font-family: 'f1-bold';
  }
}

.ff-admm {
  &-regular {
    font-family: 'din-next-lta-arabic-regular';
  }

  &-bold {
    font-family: 'din-next-lta-arabic-heavy';
  }

  &-condensed-regular {
    font-family: 'din-pro-condensed-regular';
  }
}
