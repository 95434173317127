.card {
  background-color: transparent !important;
  .table {
    color: $white !important;
    &.table-highlighted {
      & tbody {
        & tr {
          transition: all 0.3s;
        }
        & tr:hover {
          background-color: lighten($secondary, 5) !important;
          transition: all 0.3s;
        }
      }
    }

    & thead {
      background: lighten($secondary, 6) !important;
    }
  }
  background-color: transparent !important;

  & .form-control {
    &.form-control-solid {
      background-color: $dark-secondary !important;
    }
  }

  & .rs-clear-filter {
    background-color: $dark-secondary !important;
  }
}

.table-cut-corner-polygon {
  width: 100%;
  height: 100%;
  @include cut-corner-polygon-6(
    absolute,
    25px 0,
    100% 0,
    100% calc(100% - 50px),
    calc(100% - 50px) 100%,
    0 100%,
    0 25px
  );
}

.top-cut-corner-polygon {
  width: 100%;
  height: 100%;
  clip-path: polygon(
    0 25px,   
    25px 0,   
    100% 0, 
    100% 100%, 
    0 100%  
  );
}

.diagonal-cut-corner-polygon {
  width: 100%;
  height: 100%;
  clip-path: polygon(
    0 25px,   
    25px 0,   
    100% 0, 
    100% calc(100% - 25px), 
    calc(100% - 25px) 100%,
    0 100%  
  );
}