
  .radio-wrapper.active {
    background-color: $primary-light; 
    opacity: 0.6;
  }

  .radio-input-wrapper {
    background-color: transparent; 
    transition: all 0.3s ease;
  }
  .radio-label {
    color: $wnt-gray-900;
    font-size: 1.075rem;
    font-weight: 600 !important;
  }
  .radio-input-wrapper.active {
    border-color: $primary-light; 
    background-color: $primary-light; 
    opacity: 0.6;
    color: $primary;
  }
  
  .radio-input-wrapper.active .form-check-label {
    color: $primary; 
  }

  .radio-input-description  {
    color: $wnt-gray-900;
  }

  .radio-input-wrapper.active .form-check-input {
    background-color: $primary; 
    flex-shrink: 0;

  }
  .radio-input-wrapper .form-check-input {
    background-color: $wnt-gray-200;
    flex-shrink: 0;
    border-color: $secondary-light
  }