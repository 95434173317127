.swal2-popup {
  background-color: $content-bg-color !important;
  &.bg-primary-cp-dark {
    background-color: darken($primary, 15) !important;
    border-radius: 0px !important;
  }

  .swal2-html-container,
  .swal2-content {
    color: $white !important;
  }
}
