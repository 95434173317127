.react-dropdown-tree-select {
  .dropdown {
    position: relative;
    display: table;
    .tag {
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      color: $input-color;
      background-color: $content-bg-color !important;
      padding: 10px 5px 10px 10px;
      border-radius: 2px;
      display: inline-block;
      &:focus-within {
        background-color: $input-solid-bg;
        border-color: $input-solid-bg;
      }
    }
    .tag-remove {
      color: #a0a0a0;
      line-height: 100%;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      margin-left: 5px;
      &.disabled,
      &.readOnly {
        cursor: not-allowed;
      }
      &:focus {
        color: #3c3c3c;
      }
    }
    .node > label {
      cursor: pointer;
      margin-left: 2px;
    }
    .tag-list {
      display: inline;
      padding: 0;
      margin: 0;
    }
    .tag-item {
      display: inline-block;
      margin-right: 4px;
      margin-bottom: 4px;
      .search {
        display: block;
        width: 100%;
        padding: $input-padding-y $input-padding-x;
        font-family: $input-font-family;
        @include font-size($input-font-size);
        font-weight: $input-font-weight;
        line-height: $input-line-height;
        border: 1px;
        background-color: $input-solid-bg;
        border-color: $input-solid-bg;
        @include placeholder($input-solid-placeholder-color);
        color: $input-solid-color;
        transition: $transition-input;

        @include border-radius($input-border-radius, 0);

        @include box-shadow($input-box-shadow);
        @include transition($input-transition);
        &:active,
        &.active,
        &:focus,
        &:focus-visible {
          background-color: $input-solid-bg-focus;
          border-color: $input-solid-bg-focus;
          color: $input-solid-color;
          transition: $transition-input;
        }
      }
      &:last-child {
        margin-right: 4px;
      }
    }
    .node {
      list-style: none;
      white-space: nowrap;
      padding: 4px;
    }
    .node.leaf.collapsed {
      display: none;
    }
    .node.disabled > * {
      color: gray;
      cursor: not-allowed;
    }
    .node.match-in-children.hide .node-label {
      opacity: 0.5;
    }
    .toggle {
      white-space: pre;
      margin-right: 10px;
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      cursor: pointer;
      &:after {
        content: ' ';
      }
      &.collapsed {
        &:after {
          content: '+';
        }
      }
      &.expanded {
        &:after {
          content: '-';
        }
      }
    }
    .searchModeOn .toggle {
      display: none;
    }
    .checkbox-item,
    .radio-item {
      vertical-align: middle;
      margin: 0 4px 0 0;
      &.simple-select {
        display: none;
      }
    }
    .hide:not(.match-in-children) {
      display: none;
    }
  }
  .dropdown-trigger {
    display: inline-block;
    overflow: auto;
  }
  .dropdown-content {
    position: absolute;
    padding: 10px;
    min-width: 300px;
    z-index: 1;
    background-color: $content-bg-color !important;
    -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
    ul {
      margin: 0;
      padding: 0;
    }
  }
}
