$wnt-bg: #0b0d0f; // #151521
$wnt-content-bg: #17181c; // #1e1e2d || #17181c
$wnt-logo-footer-bg: #121418; // #1b1b28
$wnt-icon-default: #a8b0b7; // #494b74 // #b5b5c3

$wnt-gray-100: #121418; // #1b1b28
$wnt-gray-200: #323438; // #2b2b40 // #282a3d
$wnt-gray-300: #505256; // #323248
$wnt-gray-400: #636569; // #393951
$wnt-gray-500: #8a8c91; // #565674
$wnt-gray-600: #989899; // #6d6d80
$wnt-gray-700: #a1a1a1; // #92929f
$wnt-gray-800: #adadad; // #cdcdde
$wnt-gray-900: #b1b1b1; // #ffffff
