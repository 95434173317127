.card-circles {
  .circle {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #0b0d0f;
    &:nth-child(1) {
      top: 50%;
      transform: translateY(-50%);
      right: -10px;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      left: -10px;
    }

    &:nth-child(3) {
      top: -10px;
      left: -10px;
    }

    &:nth-child(4) {
      top: -10px;
      right: -10px;
    }

    &:nth-child(5) {
      bottom: -10px;
      left: -10px;
    }

    &:nth-child(6) {
      bottom: -10px;
      right: -10px;
    }
  }
}

.card-inner-circles {
  .circle {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;

    &:nth-child(1) {
      top: -10px;
      left: -10px;
    }

    &:nth-child(2) {
      top: -10px;
      right: -10px;
    }

    &:nth-child(3) {
      bottom: -10px;
      left: -10px;
    }

    &:nth-child(4) {
      bottom: -10px;
      right: -10px;
    }
  }
}
