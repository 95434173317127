.bg-light {
  background: $secondary !important;
}

.bg-light-primary {
  background-color: lighten($secondary, 8) !important;
}

.bg-info {
  background-color: $secondary !important;
}

.bg-primary {
  background-color: $secondary !important;
}

.rs-bg-primary {
  background-color: $secondary !important;
}

.rs-bg-transparent {
  background-color: transparent !important;
}
