.badge {
  border-radius: 0 !important;
}

@each $name, $value in $theme-colors {
  .badge-#{$name} {
    color: theme-inverse-color($name);
    background-color: $value;
  }

  .badge-light-#{$name} {
    color: $value;
    background-color: theme-dark-color($name);
  }
}
