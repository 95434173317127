// Form control
.input-group {
  &.input-group-solid {
    background-color: darken($secondary, 2);
    @include border-radius(0);

    &.input-group-sm {
      @include border-radius(0);
    }

    &.input-group-lg {
      @include border-radius(0);
    }

    .input-group-text {
      background-color: transparent;
      border-color: transparent;
    }

    .form-control {
      background-color: transparent;
      border-color: transparent;
    }
  }
}
