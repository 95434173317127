.flow-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.flow-container .row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  min-height: 0;
}

.flow-editor {
  // height: 100%;
  height: 80vh;
  width: 55%;
  border: 1px solid #ffffff40;
  border-radius: 5px;
  background-color: #fff;
}

.flow-toolbar {
  height: 100%;
  width: 15%;
}

.flow-settings {
  height: 80vh;
  width: 30%;
  overflow-x: auto;
}

.flow-settings::-webkit-scrollbar{
  width: 4px !important;
}

// .flow-settings::-webkit-scrollbar-thumb{
//   background: white !important;
// }

.flow-settings button.btn {
  width: 100%;
}

.flow-settings .fs-fieldgroup {
  padding: 15px;
  border: 1px solid #a1a1a159;
  display: grid;
  grid-template-columns: 100%;
  background: #ffffff14;
  border-radius: 5px;
  margin-bottom: 10px;
}

.flow-settings .form-date-input {
  padding: 0.1rem 0.5rem 0.1rem 0.5rem !important;
}

.flow-settings .form-date-input input,
.flow-settings .form-date-input span {
  font-size: .9rem !important;
}

.flow-settings .react-date-picker__button {
  padding: 0 5px !important;
}

.flow-settings .react-date-picker__button svg {
  width: 13px !important;
}

.flow-settings .fs-input input {
  display: block;
  background-color: #121418;
  color: #a1a1a1;
  transition: color 0.2s ease, background-color 0.2s ease;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem !important;
  width: 100%;
  border: 1px solid;
  border-color: #121418 !important;
  border-radius: 0.475rem;
  font-size: .9rem !important;
  font-weight: 500;
}

.flow-settings .fs-select {
  display: block;
  background-color: #121418;
  color: #a1a1a1;
  transition: color 0.2s ease, background-color 0.2s ease;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem !important;
  width: 100%;
  border: 1px solid;
  border-color: #121418 !important;
  border-radius: 0.475rem;
  font-size: .9rem !important;
  font-weight: 500;
}

.flow-settings .fs-group select {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem !important;
  font-size: .9rem !important;
}

.flow-settings .fs-sendlabel {
  color: green;
}


.flow-settings .fs-group .select__control {
  padding: 0rem 0.5rem 0rem 0.5rem !important;
  font-size: 1rem !important;
  background-color: #121418;
  border: 1px solid;
  border-color: #121418 !important;
  border-radius: 0.475rem;
  font-size: 0.9rem !important;
  font-weight: 500;
  min-height: 25px !important;
}

.flow-settings .fs-group .select__multi-value__label {
  color: hsl(0deg 0% 100% / 61%) !important;
}

.flow-settings .fs-group .select__multi-value {
  background-color: hsl(0deg 11.7% 96.43% / 23%) !important;
}

.flow-settings .fs-group .select__value-container {
  padding: 0px;
} 

.flow-settings {
  .fs-group {
    .select__indicator {
      padding: 0px 8px !important;
      svg {
        width: 15px !important;
      }
    }
    .select__menu {
      background-color: #121418 !important;
      border: 2px solid !important;
    }
    .select__menu-list {
      font-size: 0.9rem !important;
    }
    .select__option--is-focused {
      // background-color: #cdd3de52 !important;
      background-color: rgb(247 245 245 / 17%) !important;
    }
  }
} 
// <div class="select__option select__option--is-focused css-1n7v3ny-option" aria-disabled="false" id="react-select-2-option-3" tabindex="-1">submitted</div>

.flow-settings .fs-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 12px;
}

.flow-settings .fs-input {
  margin-bottom: 10px;
}

.flow-settings label {
  margin-bottom: 0.5rem;
  font-size: 1rem !important;
  font-weight: 500;
  color: #adadad;
}

aside .dndnode {
  padding: 5px;
  border: 1px solid;
  border-color: white;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: grab;
  border-radius: 5px;
}

.react-flow__node.circle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.react-flow__node.annotation {
  border-radius: 0;
  text-align: left;
  background: white;
  border: none;
  line-height: 1.4;
  width: 225px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
}

.react-flow__node.annotation .react-flow__handle {
  display: none;
}

//for selected node
.react-flow__node-default.selectable.selected,
.react-flow__node-default.selectable:focus,
.react-flow__node-default.selectable:focus-visible,
.react-flow__node-input.selectable.selected,
.react-flow__node-input.selectable:focus,
.react-flow__node-input.selectable:focus-visible,
.react-flow__node-output.selectable.selected,
.react-flow__node-output.selectable:focus,
.react-flow__node-output.selectable:focus-visible,
.react-flow__node-group.selectable.selected,
.react-flow__node-group.selectable:focus,
.react-flow__node-group.selectable:focus-visible,
.react-flow__node-custom.selectable.selected,
.react-flow__node-custom.selectable:focus,
.react-flow__node-custom.selectable:focus-visible {
  box-shadow: 0 0 0 0.5px #183ec7 !important;
  border: 1px solid #183ec7 !important;
}

// handle for default nodes
.react-flow__node-default .react-flow__handle,
.react-flow__node-input .react-flow__handle,
.react-flow__node-output .react-flow__handle,
.react-flow__node-group .react-flow__handle,
.react-flow__node-custom .react-flow__handle {
  width: 20px;
  height: 10px;
  border-radius: 2px;
  background-color: #778899;
}

.react-flow__node-custom {
  font-size: 10px;
  width: 180px;
  background: #f5f5f6;
  color: #222;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  border-radius: 2px;
  border: 1px solid #121418;
}

.react-flow__node-custom .react-flow__handle {
  width: 20px;
  height: 10px;
  border-radius: 2px;
  background-color: #778899;
}

.custom-node__body {
  padding: 5px;
  margin-bottom: 5px;
}

.custom-node__body select {
  width: 100%;
}

.text-start .fs-input input {
  display: block;
  background-color: #121418;
  color: #a1a1a1;
  transition: color 0.2s ease, background-color 0.2s ease;
  padding: 0.75rem 1rem 0.75rem 1rem;
  width: 100%;
  border: 1px solid;
  border-color: #121418 !important;
  border-radius: 0.475rem;
  font-size: 1.1rem;
  font-weight: 500;
}