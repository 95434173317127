.customer-file-grid {
  height: 50vh;
}

.post-file-grid {
  height: 260px;
}

.file-grid {
  &__text-label {
    @extend .form-label;
  }

  &-drop-area {
    @extend .form-control;
    @extend .form-control-solid;

    min-height: 50vh;
    overflow-y: clip;

    background-color: transparent;
    border: none;
    padding: 25px;

    &--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &-thumbnail {
      height: 8.6rem;
      position: relative;
      padding: 0.1rem;
      border-radius: 5px;

      &__btn {
        padding: 10px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 8.1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        word-break: break-word;
      }

      &__close-button {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin: 0;
      }

      &__image {
        object-fit: contain;
        width: 8rem;
        height: 8rem;
      }
    }
  }
}
