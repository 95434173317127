//
// Reseller Form Select
//

.form-select {
  box-shadow: none !important;

  // Solid style
  &.form-select-solid {
    @include placeholder($input-solid-placeholder-color);
    background-color: darken($secondary, 2);
    border-color: $secondary;
    color: $white;
    transition: $transition-input;
    border-radius: 0 !important;
    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: ($secondary);
      border-color: lighten($secondary, 5) !important;
      color: $white;
      transition: $transition-input;
      border-radius: 0 !important;
    }
  }

  // White style
  &.form-select-white {
    @include placeholder($input-placeholder-color);
    background-color: $body-bg;
    border-color: $body-bg;
    color: $input-color;
    border-radius: 0 !important;
    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $body-bg;
      border-color: $body-bg !important;
      color: $input-color;
    }
  }
}
