.option-input-item {
    @extend .mb-5;
  }
  
  .option-input-item-input-container {
    display: flex;
    align-items: center;
    & > * {
      &:not(:last-child) {
        @extend .me-5;
      }
    }
    &__select-input {
      flex-grow: 1;
    }
    &__number-input {
      width: 7rem;
    }
    &__order-input {
      width: 5rem;
    }
  }
  