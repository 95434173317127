.searchable-select-input-container {
  // border: 1px solid darken($secondary, 10);
  & .search-input {
    color: $white !important;
    background-color: $secondary !important;
    border-radius: 0;
  }
  & .searchable-select-input {
    color: $white !important;
    background-color: darken($secondary, 2) !important;
    border-radius: 0;
  }

  & .searchable-select-input-list-content {
    background-color: lighten($secondary, 6) !important;
    border-radius: 0 !important;
  }
}
