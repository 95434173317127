@use 'sass:math';

.digital-menu-basket-page {
  @extend .container;
  & .page-header {
    width: 100%;
    background-color: $content-bg-color;
    padding: math.div($spacer, 3) 0;
    position: fixed;
    display: flex;
    align-items: center;
    min-height: $spacer * 5;
    z-index: $zindex-sticky;
  }

  & .page-content {
    padding: 5rem 0;
  }
}
