.tag-container * {
    box-sizing: border-box;
    transition: all 0.2s ease;
}

.tag-container {
    --tag-bg-color: #121418 !important;
    --tag-border: none !important;
    // --rti-main: #3182ce;
    --rti-main: transparent !important;
    --tag-radius: 5px !important;
    --tag-s: 0.5rem;

    /* Container Styles */
    align-items: center;
    background: var(--tag-bg-color);
    border: 1px solid var(--tag-border);
    border-radius: var(--tag-radius);
    display: block;
    // flex-wrap: wrap;
    gap: var(--tag-s);
    line-height: 1.4;
    padding: var(--tag-s);
    min-height: 100px !important;
    height: 100% !important;
}

.tag-container:focus-within {
    border-color: var(--rti-main);
    box-shadow: var(--rti-main) 0px 0px 0px 1px;
}

.tag--input {
    border: 0;
    outline: 0;
    // font-size: inherit;
    line-height: inherit;
    width: 30%;
    font-size: 14px !important;
    height: 35px !important;
    color: #adadad !important;
    background: #121418 !important;
    font-weight: 500 !important;
}

.tag {
    align-items: center;
    // background: var(--rti-tag);
    border-radius:  5px !important;
    display: inline-flex;
    justify-content: center;
    padding:0.15rem 0.25rem;

    margin-right: 5px !important;
    color: #b1b1b1 !important;
    background: #323438 !important;
    cursor: pointer !important;
    font-weight: 600 !important;
    margin-bottom: 5px !important;
    height: 25px !important;
    
}

.tag button {
    background: none;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    line-height: inherit;
    padding: 10px !important;
}

.tag button:hover {
    color:  #e53e3e;
}