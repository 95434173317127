.date {
  font-size: 10px;
  color: gray;
}

.lastMessage {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.userImage {
  width: 50px;
}

.cardEffect {
  transition: all 0.5s;
  border-radius: 0 !important;
  &:hover {
    background: #0e0f12;
  }
}
