.poken-container {
  // background-color: lighten($wnt-gray-900, 15);
  .drag-drop-file-input__text-label {
    // @extend .text-light;
    @extend .fs-3;
  }
  & .drag-drop-file-input-drop-area {
    // background-color: lighten($wnt-gray-900, 15);
    // border-color: lighten($wnt-gray-900, 15);
    border-radius: 0px;
  }
  & .content {
    background-color: transparent;
  }
  & .bg-primary-light {
    background-color: lighten($primary, 30) !important;
  }
}

.pkn-text-secondary {
  color: $pkn-orange;
}

.pkn-bg-secondary {
  background-color: $pkn-orange;
}

// .auth-page {
//   background-color: lighten($wnt-gray-900, 15);
//   & .form-control.form-control-solid,
//   .input-group.input-group-solid {
//     background-color: lighten($wnt-gray-900, 15);
//     border-color: lighten($wnt-gray-900, 15);
//     border-radius: 0px;
//     color: $wnt-gray-300;

//     & .btn-bg-light {
//       background-color: lighten($wnt-gray-900, 15);
//     }

//     &:focus {
//       background-color: lighten($wnt-gray-900, 5);
//       border-color: lighten($wnt-gray-900, 5);
//       color: $wnt-gray-300;
//       & > .btn-bg-light {
//         background-color: lighten($wnt-gray-900, 5);
//       }
//     }
//   }
// }
