@use 'sass:math';
@import '../mixin/corner_mixin';
$corner-positions: (
  'left': null,
  'right': null,
);

$corner-value: (
  '5': 5%,
  '7': 7%,
  '10': 10%,
  '15': 15%,
  '25': 25%,
  '30': 30%,
  '50': 50%,
  '65': 65%,
  '75': 75%,
  '80': 80%,
  '85': 85%,
  '95': 95%,
);

@each $name in $corner-positions {
  @each $key, $value in $corner-value {
    .cut-corner-polygon-top-#{$name}-#{$key} {
      width: 100%;
      height: 100%;
      @include cut-corner-polygon-5(absolute, $value 0, 100% 0, 100% 100%, 0 100%, 0 $value);
    }

    .cut-corner-polygon-bottom-#{$name}-#{$key} {
      width: 100%;
      height: 100%;
      @include cut-corner-polygon-5(absolute, 0 0, 100% 0, 100% $value, $value 100%, 0 100%);
    }
  }
}
