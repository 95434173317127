.root {
  position: relative;
}

.input {
  display: flex;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2rem !important;
}

.inputHasClear {
  padding-right: 3.8rem !important;
}

.inputText {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.buttonContainer {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: black;
}

.dropdown {
  position: absolute;
  top: calc(1rem + 100%);
  width: 100%;
  z-index: 100;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }
}
