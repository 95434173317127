.foh-layout-root {
  & .navbar {
    height: 6rem;
    padding: 0 4rem;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xl) {
      padding: 0 1rem;
    }
  }

  & .foh-content {
    display: flex;
    height: calc(100vh - 6rem);

    & .body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:not(:last-child) {
        margin-right: 2rem;
        @include media-breakpoint-down(xl) {
          margin-right: 0.5rem;
        }

        & .foh-header {
          @include border-top-end-radius($border-radius-lg);
        }
      }

      & .page-title {
        height: 5rem;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: 0 4rem;

        & .title-text {
          margin: 0;
        }
      }

      & .content {
        padding: 2rem 4rem;
        flex-grow: 1;
        overflow-y: auto;

        @include media-breakpoint-down(xl) {
          padding: 1rem;
        }
      }
    }

    & .sidebar {
      @include border-top-start-radius($border-radius-lg);

      width: 25%;
      height: 100%;
      padding: 4rem;
      flex-shrink: 0;

      @include media-breakpoint-down(xl) {
        padding: 1rem;
      }
    }
  }
}
