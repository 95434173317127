.swal2-popup {
  background: transparent !important;
  padding: 25px;
  border-radius: 0;
  & .swal2-icon,
  .swal2-title,
  .swal2-textarea {
    z-index: 2;
  }
  & .swal2-title {
    color: $white !important;
  }

  .swal2-corners {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: lighten($secondary, 12) !important;
    clip-path: polygon(
      25px 0,
      100% 0,
      100% calc(100% - 50px),
      calc(100% - 50px) 100%,
      0 100%,
      0 25px
    );
  }

  & .form-select-solid,
  & .form-control-solid {
    background: lighten($secondary, 2) !important;
  }
}
