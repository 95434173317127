.root {
  position: relative;
  flex-shrink: 0;

  /* Prevent the browser's built-in drag from interfering */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  opacity: 0.5;
  transition: opacity 300ms;

  &:hover {
    opacity: 1;
  }
}

.dragIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.dragIndicatorDot {
  width: 6px;
  height: 6px;
  border-radius: 100px;
}

.horizontal {
  width: 3px;
  cursor: col-resize;
}

.vertical {
  height: 3px;
  cursor: row-resize;
}
