.root {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: space-between;

  & button {
    height: 100%;
    transition: all 300ms;
  }
}
