.activity-timeline {
  overflow-x: auto;

  &__line {
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 0.6rem;
  }

  &__item-container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &-item {
    &-dot {
      z-index: 1;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
