.btn-dark-primary-linear-gradient-dw {
  background-color: darken($primary, 20) !important;
  color: white !important;
}

.btn-light-primary-linear-gradient-dw {
  background: linear-gradient(
    to top,
    darken($primary, 25) 0%,
    darken($primary, 20) 20%,
    darken($primary, 15) 50%,
    lighten($primary, 0) 100%
  ) !important;
  color: white !important;
}

.second-nav-botton-dw {
  @extend .btn-dark-primary-linear-gradient-dw;
  border-radius: 40px;
  color: white;
  &.btn-active {
    @extend .btn-light-primary-linear-gradient-dw;
  }
}
