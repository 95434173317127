@import '../../core/vendors//plugins/formvalidation';
@import '../../fonts';
.digital-waiver-container {
  font-family: 'din-next-lta-arabic-regular';

  & .bg-brown {
    background-color: $brown;
  }

  & .text-danger {
    color: $danger !important;
  }

  & .bg-danger {
    background-color: $danger !important;
  }

  & button.digital-waiver-nav-button {
    @extend .fs-4;
    @extend .ff-admm-bold;
  }

  .bg-primary-dw-dark {
    background-color: darken($primary, 20) !important;
    color: $white;
  }

  .bg-primary-dw-darker {
    background-color: darken($primary, 27) !important;
    color: $white;
  }

  .bg-primary-dw-light {
    background-color: darken($primary, 10) !important;
    color: $white;
  }

  .radius-bottom-3 {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .sigCanvas {
    background-color: $white;
    padding: 5px;
  }

  .radius-bottom-0 {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .filter-button-dw {
    @extend .bg-primary-dw-dark;
    position: relative;
    transition: background-color 0.3s;
    &.active {
      height: 57px;
    }
    &.mobile-active {
      height: 50px;
    }
  }
  .filter-group-by-button {
    @extend .bg-primary-dw-dark;
    transition: background-color 0.3s;
    &:hover {
      background-color: darken($primary, 12) !important;
    }
  }

  .form-label {
    text-transform: uppercase !important;
  }
  .filters-box-dw {
    @extend .bg-primary-dw-dark;
    border-radius: 0px;
  }

  .auth-dw-field {
    background-color: lighten($primary, 27) !important;
    border: 1px solid lighten($primary, 27) !important;
    color: darken($primary, 15) !important;
    border-radius: 5px !important;

    &:hover {
      background-color: lighten($primary, 5) !important;
    }
  }

  .PhoneInput {
    height: 100%;
  }

  .react-date-picker__wrapper {
    height: 100%;
  }

  .header {
    height: 55px !important;
    background-color: $brown !important;
  }

  .toolbar {
    height: 75px !important;
    top: 55px !important;
    // background-color: transparent !important;
  }

  .btn-linear-gradient-black-white-dw {
    background: linear-gradient(
      to top,
      darken($dark, 15) 0%,
      darken($dark, 7) 10%,
      darken($dark, 3) 20%,
      lighten($dark, 80) 50%,
      lighten($dark, 100) 100%
    ) !important;
  }

  .drawer-container {
    & > * {
      @extend .pe-0;
      @extend .py-5;
      @extend .ps-0;
    }
  }
}
