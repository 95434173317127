@import 'src/_metronic/assets/sass/style.dark.scss';

.textInputFilter {
  width: 300px;
}

@include media-breakpoint-down(md) {
  .textInputFilter {
    width: 100%;
  }
}

.waiverImageCardContainer {
  height: 135px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.flipCard {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  backface-visibility: hidden;
  min-height: 255px;
}
