.foh-product-grid {
  &-item {
    background: none;
    border: none;
    width: 100%;

    &-thumbnail {
      @extend .rounded;
      height: 15rem;

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-label {
      @extend .text-white;
      @extend .fs-5;
      @extend .mt-3;

      & &__name {
        @extend .fs-2;
        @extend .fw-bolder;
      }

      display: flex;
      justify-content: space-between;
    }
  }
}
