.customer-portal-container {
  table {
    border: 0.2px solid $wnt-gray-300;
    > thead > tr {
      border-bottom: 0.2px solid $wnt-gray-300 !important;

      > th {
        background-color: $wnt-content-bg;
        border-right: 0.2px solid $wnt-gray-300;
        padding-top: 0px !important;
        padding-bottom: 0px !important;

        &:first-child {
          padding-left: 20px !important;
          padding-right: 0px !important;
          border-right: none;
        }
        &:last-child {
          padding-right: 20px !important;
        }

        &.table-arrow-grouping {
          padding-left: 0px !important;
          padding-right: 0px !important;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          border-left: none;
          border-right: none;
        }

        > button,
        span {
          margin: auto;
          text-align: center !important;
          margin-top: 20px !important;
          margin-bottom: 20px !important;
          color: white !important;
          font-family: 'din-pro-condensed-black';
          font-size: 16px !important;
        }

        &.grouped-column {
          > span {
            text-align: start !important;
          }
        }
      }
    }

    > tbody {
      > tr {
        border-bottom: 0.2px solid $wnt-gray-300 !important;

        &:nth-child(odd) {
          background-color: lighten($wnt-content-bg, 4);
        }

        &:nth-child(even) {
          background-color: $wnt-content-bg;
        }

        &.table-row-group {
          background-color: darken($primary, 20) !important;
          &:hover {
            background-color: darken($primary, 22) !important;
          }
        }
        &:hover {
          background-color: darken($wnt-content-bg, 7) !important;
        }
        > td {
          border-right: 0.2px solid $wnt-gray-300;
          padding-top: 10px !important;
          padding-bottom: 10px !important;
          text-align: center;
          color: white !important;
          &.grouped-column {
            text-align: start !important;
          }
          &.table-arrow-expand {
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            border-right: none !important;
          }
          &.table-checkbox-expanded {
            border-right: none !important;
          }
          &.table-row-empty-box {
            border-right: none !important;
          }
          &.table-row-empty-box-none {
            display: none !important;
          }
          &:first-child {
            padding-left: 20px !important;
            border-right: none;
          }
          &:last-child {
            padding-right: 10px !important;
            border-left: none;
          }

          > div {
            justify-content: flex-end !important;
          }
        }
      }
    }
  }
  .table-action-header {
    @extend .pt-4;
    @extend .px-4;
  }
}
