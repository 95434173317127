.or-divider {
  font-size: 1.2rem;
  position: relative;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.2);
    left: 0;
    top: 50%;
    width: calc(50% - 1rem - 1em);
  }
  &::after {
    content: '';
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.2);
    right: 0;
    top: 50%;
    width: calc(50% - 1rem - 1em);
  }
}
