.outlet-food-menu {
  & .banner {
    width: 100%;
    object-fit: cover;
    height: 18rem;
  }

  & .content {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    & .category-tabs {
      position: sticky;
      top: 0;
      z-index: 10;
    }

    & .outlet-menu {
      @extend .container;
      @extend .mb-15;
    }
  }
}
