.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;

  .backButton {
    position: absolute;
    left: 0.2rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .guestName {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .infoButton {
    position: absolute;
    right: 0.2rem;
    top: 0.35rem;
  }
}

.content {
  flex-grow: 1;
  overflow: auto;
}

.footer {
  flex-shrink: 0;
  height: 5rem;
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: space-between;

  a {
    display: block;
    width: 33.333%;
  }

  button {
    height: 100%;
    width: 100%;
  }
}

.service {
  display: block;
  width: 33.333%;
}
