.rs-wizard {
  & .bg-body {
    background-color: transparent !important;
  }
  .wizard-cut-corner-polygon {
    width: 100%;
    height: 100%;
    @include cut-corner-polygon-6(
      absolute,
      25px 0,
      100% 0,
      100% calc(100% - 50px),
      calc(100% - 50px) 100%,
      0 100%,
      0 25px
    );
  }
}
