.imageContainer {
  height: 5rem;
  width: 5rem;
  flex-shrink: 0;
}

.imageContainerEmpty {
  padding: 0;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.imageEmpty {
  border-radius: 2000rem;
  background-color: white;
  overflow: hidden;
  padding: 0.8rem;
}
