.splash-screen {
  opacity: 0;

  &-fade-in-scroll {
    animation: fadeInOut 2.5s;
    animation-fill-mode: forwards;
  }

  &-children-show {
    animation: fadeIn 1s;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  90% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
