.seat-map-selection-input {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;

  & .seat-map-selection-controls {
    position: absolute;
    top: 1rem;
    left: 5rem;
    z-index: 1;
  }
}
