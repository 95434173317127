body:has(div):has(.reseller-container) {
  @import '../colors';
  @import '../variables';
  @import '../mixin/color_mixin';
  @import './background';
  @import './corner';
  @import '../forms//base';
  @import './badge';
  @import './button';
  @import './svg';
  @import './table';
  @import './wizard';
  @import './swal';
  @import './nav';
  @import './dashboard';
  @import './pagination';
  @import './stepper';

  .reseller-container {
    #kt_header,
    #kt_footer {
      background: lighten($secondary, 6);
    }

    #kt_content,
    #kt_toolbar_container {
      background: $secondary;
    }

    .text-primary {
      color: $primary !important;
    }
    .text-active {
      color: $active !important;
    }
    .text-dark {
      color: $dark !important;
    }
    .text-secondary {
      color: $secondary !important;
    }
    .text-light {
      color:  darken($light, 5) !important
    }
    .text-big {
      font-size: 60px;
    }

    a {
      color: $primary;
    }

    .text-active-primary {
      color: $light !important;
      &.active {
        color: $primary !important;
      }
    }

    .rs-gradient {
      background: linear-gradient(
        to bottom,
        lighten($secondary, 10),
        $secondary,
        lighten($secondary, 10)
      ) !important;
      border-radius: 0px;
      & .card {
        background: transparent !important;
      }
    }
    .rs-main-bg {
      position: relative;
      border-radius: 0px;
    }

    .rs-main-bg::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        lighten($secondary, 10%),
        $secondary,
        darken($secondary, 10%)
      ) !important;
      border-radius: inherit;
      z-index: -1;
    }

    .rs-main-bg::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../../../../../public/media/saas/admm-full-pattern.png');
      background-size: 100% auto;
      background-position: bottom center;
      background-repeat: no-repeat;
      z-index: 1;
    }

    .rs-table-bg {
      position: relative;
      border-radius: 0px;
    }

    .rs-table-bg::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        lighten($secondary, 10%),
        $secondary,
        darken($secondary, 10%)
      ) !important;
      border-radius: inherit;
      z-index: -1;
    }

    .rs-table-bg::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../../../../../public/media/saas/admm-left-pattern.png');
      background-size: 65% auto;
      background-position: bottom left;
      background-repeat: no-repeat;
      z-index: 1;
    }

    /* Add z-index to the form to make it clickable */
    .form-selector {
      position: relative;
      z-index: 4; /* Adjust this value as needed */
    }

    .rs-top {
      z-index: 10; /* Adjust this value as needed */
    }

    .rs-breadcrumbs {
      z-index: 1001 !important;
    }
    .rs-header {
      z-index: 1002 !important;
    }

    .drawer {
      background-color: lighten($secondary, 3) !important;
      z-index: 1003 !important;
    }
  }

  & .rs-advanced-filter {
    background-color: lighten($secondary, 15) !important;
    & .form-select-solid,
    & .searchable-select-input,
    & .form-control-solid {
      background-color: lighten($secondary, 8) !important;
    }
    z-index: 1000;
  }
  & .menu-sub-dropdown {
    background-color: lighten($secondary, 10) !important;
    color: $white !important;
  }

  & .modal-content {
    background: transparent !important;
    border-radius: 0;
    & .swal2-corners {
      background: lighten($secondary, 12) !important;
      width: 100%;
      height: 100%;
      @include cut-corner-polygon-6(
        absolute,
        25px 0,
        100% 0,
        100% calc(100% - 50px),
        calc(100% - 50px) 100%,
        0 100%,
        0 25px
      );
    }
    & .form-select-solid,
    & .form-control-solid {
      background: lighten($secondary, 2) !important;
    }
  }
  .seat-map-editor {
    background-color: #031529 !important;
  }
}
