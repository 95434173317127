.seat-map-editor {
  position: relative;
  overflow: hidden;

  & .seat-map-editor-controls {
    top: 1rem;
    left: 1rem;
    position: absolute;
    z-index: 1;

    & > * {
      @extend .mb-1;
    }
  }

  & .seat-map-editor-other-btn {
    top: 1rem;
    right: 1rem;
    position: absolute;
    z-index: 1;
  }
}

.seat-map-editor-controls {
  display: flex;
  flex-direction: column;
}
