.drawer-container {
  width: 100%;

  & > * {
    @extend .pe-5;
    @extend .py-5;
    @extend .ps-5;
  }
}

.form-drawer-container {
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.drawer-header {
  border-bottom: 1px solid $card-border-dashed-color;
}

.drawer-body {
  flex-grow: 1;
  overflow-y: auto;
}

.drawer-footer {
  border-top: 1px solid $card-border-dashed-color;
}
