.modal {
  & .modal-wide {
    width: 90%;
    max-width: none;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    & .modal-content-big {
      height: 90%;
    }
  }
}

.swal2-popup {
  &.swal2-popup__big {
    width: 80vw;
    height: 80vh;
  }
  & .swal2-html-container {
    & .swal2-html-portal {
      display: contents;
    }
    & .swal2-main-modal {
      background-color: $wnt-gray-100;
      position: absolute;
      float: left;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.swal2-popup__autoHeight {
    width: 80vw;
    height: auto;
  }
}

.pdf-modal {
  height: 80vh !important;
}

.center-modal {
  // Mobile dialog
  .modal-dialog {
    position: fixed;
    top: 50%; /* Move the modal to the vertical center */
    left: 50%; /* Move the modal to the horizontal center */
    transform: translate(-50%, -50%); /* Adjust for both vertical and horizontal centering */
    // max-width: $sticky-width;
    width: auto;
    min-width: 400px;
    margin: 0;
    @include border-radius($border-radius);
  }
}
