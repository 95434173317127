.rs-pagination {
  z-index: 1000 !important;
  & .form-select {
    &.form-select-solid {
      background-color: $dark-secondary !important;
    }
  }
  & .pagination {
    & .page-item {
      &.previous,
      &.next {
        & .page-link {
          background-color: $dark-secondary !important;
          border-radius: 0;
        }
      }
      & .page-link {
        &:hover {
          background-color: lighten($secondary, 12) !important;
          color: $white;
        }
      }
      &.active {
        & .page-link {
          background-color: lighten($secondary, 12) !important;
        }
      }

      &:hover:not(.disabled) {
        & .page-link {
          & i {
            color: $white;
          }
        }
      }
    }
  }
}
