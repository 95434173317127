.rs-stepper {
  & .bg-primary,
  & .bg-light-primary,
  & .bg-light-success {
    border-radius: 0 !important;
    @include cut-corner-polygon-6(
      relative,
      7px 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 7px
    );
  }
  & .bg-primary {
    background: $primary !important;
    & .svg-icon {
      & svg {
        [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: $secondary;
        }
      }
    }
  }
  & .bg-light-primary {
    background: $active-dark !important;

    & .svg-icon {
      & svg {
        [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: $active;
        }
      }
    }
  }
}
