.root {
  display: flex;
}

.horizontal {
  justify-content: stretch;
}

.vertical {
  flex-direction: column;
}
