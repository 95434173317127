.user-list-input-list {
  list-style: none;
  max-height: 17rem;
  overflow-y: auto;

  &-item {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &__button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      color: white;
    }

    &-avatar {
      font-size: 2rem;
      width: 4.5rem;
      height: 4.5rem;
      margin-right: 2rem;
    }
  }
}
