.second-nav-botton {
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    top: calc(100% - 4px);
    background: $primary;
    visibility: hidden;
    border-right: 4px solid $white;
    z-index: 1;
    transition: all 0.5s ease-in-out 0.1s;
  }
  &::after {
    content: '';
    display: block;
    position: relative;
    background-color: $primary;
    min-width: 10px;
    width: 30px;
    height: 4px;
    margin-top: 7px;
    transition: all 0.5s ease-in-out 0.1s;
  }

  &:hover {
    &::before {
      width: 100%;
      visibility: visible;
    }
    &::after {
      width: 100%;
    }
  }
  &.btn-active {
    &::before {
      width: 100%;
      visibility: visible;
    }
    &::after {
      width: 100%;
    }
  }
}
