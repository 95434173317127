@import 'src/_metronic/assets/sass/wnt-colors';

.input {
  :global {
    .react-datetime-picker {
      &__wrapper {
        border: none;
      }
      &__inputGroup {
        &__input {
          color: $wnt-gray-700;
        }
      }
      &__clear-button {
        svg {
          stroke: $wnt-gray-700;
        }
      }
      &__calendar-button {
        svg {
          stroke: $wnt-gray-700;
        }
      }
    }
  }
}
