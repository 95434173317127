.outlets-page-container {
  @extend .container;
  height: 100vh;
  overflow: auto;

  & .outlets-navbar {
    background-color: $content-bg-color;
    position: sticky;
    top: 0;
    width: 100%;
  }

  & .outlets-content {
    @extend .py-5;
  }
}
