.root {
  position: 'relative';
}

.content {
  height: 100vh;

  @media only screen and (orientation: portrait) {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.main {
  flex-grow: 1;
  display: flex;
  min-height: 0;
}

.currentImageContainer {
  flex-grow: 1;
}

.noteContainer {
  flex-basis: 30%;
  flex-shrink: 0;
}

.image {
  width: 8rem;
  object-fit: contain;
  padding: 0.3rem;
}

.imageContainer {
  height: 8rem;
  padding: 0.5rem;
  display: flex;
  overflow-x: auto;
  flex-shrink: 0;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
}
