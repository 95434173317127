.pkn-or-divider {
  font-size: 1.2rem;
  position: relative;
  text-align: center;
  color: darken($pkn-primary, 30);

  &::before {
    content: '';
    position: absolute;
    border: 1px solid darken($pkn-primary, 30) !important;
    left: 0;
    top: 50%;
    width: calc(50% - 1rem - 1em);
  }
  &::after {
    content: '';
    position: absolute;
    border: 1px solid darken($pkn-primary, 30) !important;
    right: 0;
    top: 50%;
    width: calc(50% - 1rem - 1em);
  }
}
