.alert-container {
  position: fixed;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  bottom: 0;
  right: 1rem;
  min-width: 50vw;

  & .alert {
    position: relative;
    padding-right: 2.5rem;

    & .btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
