.line-grow {
  position: relative;
  padding-bottom: 10px;

  &:hover::after {
    width: 100%;
    background-color: $primary !important;
  }
  &::after {
    transition: all 0.5s ease-in-out;
    content: '';
    background-color: $primary !important;
    height: 5px;
    width: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
