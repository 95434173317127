.group-by-buttons {
  background-color: darken($primary, 25) !important;
  transition: all 0.3s;
  & .title {
    color: $white !important;
  }

  & svg {
    fill: $white !important;
  }
  &:hover {
    background-color: darken($primary, 28) !important;
    & .title {
      color: $white !important;
    }

    & svg {
      fill: $white !important;
    }
  }
}
.group-by-buttons-active {
  background-color: $wnt-gray-100 !important;
  &:hover {
    background-color: $wnt-gray-100 !important;
  }
  & .title {
    color: $white !important;
  }

  & svg {
    fill: $white !important;
  }
}
