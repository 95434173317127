@import './form-control';
@import './form-select';
@import './input';
@import './input-group';
@import './searchable-select-input';
@import './radio-input';

.form-label {
  color: $white !important;
}

.outer-container {
  margin: 5px;
}

.bordered-container {
  position: relative;
  border: 1px solid $gray-200;
  padding: 20px;
}

.floating-text {
  position: absolute;
  top: -10px;
  left: 15px;
  background-color: lighten($secondary, 7%);
  color: $white;
  padding: 0 5px;
  font-weight: 500;
  font-size: 1.05rem;

}
.box-container {
  padding-bottom: 13px;
}

.next-container {
  padding: -13px !important;
}
