$primary: #ffd000;
$secondary: #020f1f;
$active: #04aede;
$warning: #ffd000;
$danger: #a4363c;
$info: #c96fd7;
$success: #a3f67d;
$white: #ffffff;
$dark: #000000;
$light: #b1b1b2;

$dark-secondary: #021528;
$active-dark: #11344c;
$warning-dark: #5b4b02;
$danger-dark: #680e12;
$info-dark: #3f2144;
$success-dark: #5f9347;

$wnt-gray-100: #a1a1a1;
