.item {
  display: flex;
  align-items: center;
}

.colorBox {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  border: 1px solid white;
}
