.form-date-input {
  @extend .form-control;
  padding: 0.55rem 1rem;
}

.react-date-picker__calendar {
  z-index: $zindex-dropdown;
}

.number-input {
  & .number-input-field {
    display: flex;

    & .form-control {
      border-radius: 0;
    }

    &-button {
      &--left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &--right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.digit-input {
  position: relative;

  .digit-input-label {
    display: flex;
    justify-content: space-around;

    & .digit-input-item {
      min-width: 3.5rem;
      text-align: center;

      & .digit-input-item__value {
        &--focused {
          @keyframes digit-input-item-value-blink {
            0% {
              opacity: 1;
            }
            40% {
              opacity: 0;
            }
            60% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          animation-name: digit-input-item-value-blink;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
        }
      }
    }
  }

  .digit-hidden-input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 9999s ease-in-out 0s !important;
  -webkit-text-fill-color: $white !important;
  caret-color: $white !important;
  -webkit-caret-color: $white !important;
}
