.searchable-select-input-container {
  position: relative;

  & > .searchable-select-input-list-content {
    margin-top: 1rem;
  }
}

.searchable-select-input {
  @extend .form-control;
  @extend .form-control-solid;
  position: relative;
  &__input-container {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    padding-right: 1rem;
    display: block;
  }
  & .searchable-select-input-list {
    width: 100%;
  }
  & .drop-down-arrow {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: black;
  }
}

.searchable-select-input-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.searchable-select-input-list-content {
  @extend .bg-light;
  @extend .shadow-sm;
  @extend .rounded;
  padding: 1rem;
  width: 100%;
  position: absolute;
  z-index: $zindex-dropdown;
  overflow: hidden;
  &__search-container {
    position: relative;
    & .search-input {
      position: absolute;
    }
  }
  &__list-container {
    padding: 0;
    margin-top: 4rem;
    margin-bottom: 0;
    list-style-type: none;
    max-height: 12rem;
    overflow-y: auto;
    border-top: none;
    width: 100%;
  }
}

.searchable-input-item {
  overflow-x: ellipsis;
  position: relative;
  &:hover {
    &::before {
      display: unset;
    }
  }
  &::before {
    position: absolute;
    // content: 'Press to select';
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-size: 0.8rem;
    color: #555;
    display: none;   
  }
}

.searchable-input-item-botom-border {
  border-bottom: 1px solid $card-border-dashed-color;
}

.multi-level-select-container {
  .multi-level-select__control {
    background-color: $wnt-gray-100 !important;
    border-style: none;
    transition: none;
    color: #8e8e8e;
    padding: 4px;
    font-weight: 500;
    font-size: 1.1rem;
    &:hover {
      border-color: var(--border-color);
    }
  }

  .multi-level-select__menu {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
  }

  .multi-level-select__option {
    background-color: var(--bg-secondary);

    &:hover {
      background-color: var(--bg-primary);
    }
  }

  .multi-level-select__indicator-separator {
    background-color: var(--border-color);
  }

  .multi-level-select__placeholder,
  .multi-level-select__single-value {
    color: var(--text-primary);
  }
}
