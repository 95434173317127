.search-cp-field {
  background-color: darken($primary, 27) !important;
  border-color: darken($primary, 27) !important;
  color: $white !important;
}

.form-label {
  color: $white !important;
}

.form-check-input {
  background-color: darken($wnt-gray-200, 20) !important;
  &:checked {
    background-color: $primary !important;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23000000%27/%3e%3c/svg%3e');
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $wnt-gray-100 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $white !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $white !important;
}

.search-cp-field {
  background-color: darken($primary, 27) !important;
  border-color: darken($primary, 27) !important;
  color: $white !important;
}

.form-label {
  color: $white !important;
}

.form-check-input {
  background-color: darken($wnt-gray-200, 20) !important;
  &:checked {
    background-color: $primary !important;
    color: $secondary !important;
  }
}

input {
  color: $white !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $wnt-gray-100 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $white !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $white !important;
}

.rs-select-dropdown {
  background-color: lighten($secondary, 6) !important;
}
