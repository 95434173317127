@import './colors';
$theme-colors: (
  'white': $white,
  // custom color type
  'light': $light,
  'primary': $active,
  'secondary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'dark': $dark,
);

$theme-dark-colors: (
  'primary': $active-dark,
  'success': $success-dark,
  'info': $info-dark,
  'warning': $warning-dark,
  'danger': $danger-dark,
);

$input-border-radius: 0 !default;
$input-border-radius-sm: 0 !default;
$input-border-radius-lg: 0!default;
