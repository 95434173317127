.menu {
  width: 100%;
  max-width: 800px;
}

.arrow {
  visibility: hidden;
  top: -4px;

  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
}
