.order-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    min-height: 6rem;
    padding: 1rem 2rem;
  }

  &-item-list {
    flex-grow: 1;
    height: 17rem;
    overflow-y: auto;
    list-style: none;
    padding: 0 2rem;
  }

  &-actions {
    display: flex;

    &__button {
      width: 4rem !important;
      border-radius: 0;

      &--confirm {
        flex-basis: 100%;
      }

      &--close {
        width: 4rem !important;
      }
    }
  }
}

.order-card-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  &:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  &__name {
    font-weight: 600;
    font-size: 1.2rem;
  }

  &__count {
    border-radius: 0;
    min-height: 2.2rem;
    min-width: 2.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
