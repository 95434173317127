.btn {
  border-radius: 0;
  &.btn-color-primary {
    color: $primary !important;
    .svg-icon {
      & svg {
        [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: $primary !important;
        }
      }
    }
  }

  &.btn-color-warning {
    color: $warning !important;
    .svg-icon {
      & svg {
        [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: $warning !important;
        }
      }
    }
  }

  &.btn-bg-light {
    background-color: darken($secondary, 2) !important;
  }
  &.btn-bg-warning {
    background-color: darken($primary, 2) !important;
  }

  &.btn-light-primary {
    background-color: transparent !important;
    border-radius: 0 !important;
    color: $primary !important;
    border: 1px solid $primary !important;
    min-height: 44.05px;
    .svg-icon {
      & svg {
        [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: $primary;
        }
      }
    }
    &:hover:not(.btn-active),
    &:focus:not(.btn-active) {
      background-color: rgba($primary, 0.2) !important;
      color: $primary !important;
      .svg-icon {
        & svg {
          [fill]:not(.permanent):not(g) {
            transition: fill 0.3s ease;
            fill: $primary;
          }
        }
      }
    }
  }

  &.btn-primary {
    background-color: $primary !important;
    color: $secondary !important;
    .svg-icon {
      & svg {
        [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: $secondary;
        }
      }
    }
    &:hover:not(.btn-active),
    &:focus:not(.btn-active) {
      background-color: darken($primary, 6) !important;
      .svg-icon {
        & svg {
          [fill]:not(.permanent):not(g) {
            transition: fill 0.3s ease;
            fill: $secondary;
          }
        }
      }
    }
  }

  &.btn-cut-conrner {
    width: 100%;
    height: 100%;
    @include cut-corner-polygon-5(absolute, 0 0, 100% 0, calc(100% - 50px) 100%, 0 100%, 0 0);
  }
}
