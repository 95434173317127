@mixin cut-corner-polygon-5($position: absolute, $point1, $point2, $point3, $point4, $point5) {
  position: $position;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon($point1, $point2, $point3, $point4, $point5);
  clip-path: polygon($point1, $point2, $point3, $point4, $point5);
}

@mixin cut-corner-polygon-6(
  $position: absolute,
  $point1,
  $point2,
  $point3,
  $point4,
  $point5,
  $point6
) {
  position: $position;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon($point1, $point2, $point3, $point4, $point5, $point6);
  clip-path: polygon($point1, $point2, $point3, $point4, $point5, $point6);
}
