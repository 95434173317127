.customer-portal-container {
  .search-cp-field {
    background-color: darken($primary, 27) !important;
    border-color: darken($primary, 27) !important;
    color: $white !important;
  }

  .form-label {
    color: $white !important;
  }

  .form-check-input {
    background-color: lighten($wnt-gray-200, 20) !important;
    &:checked {
      background-color: $primary !important;
    }
  }

  .searchable-select-input {
    color: $white !important;
  }

  input {
    color: $white !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $white !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $white !important;
  }
}
