.react-date-picker {
  &__wrapper {
    border: none;
  }
  &__inputGroup {
    color: $input-solid-color;
    &__input {
      color: $input-solid-color;
    }
  }
  &__button {
    &__icon {
      stroke: $input-solid-color;
    }
  }
}
