//
// Reseller Form Control
//

// Form control
.form-control {
  box-shadow: none !important;

  // Dropdown shown state
  .dropdown.show > & {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
  }

  // Readonly state
  &[readonly] {
    background-color: $input-readonly-bg;
  }

  // Solid style
  &.form-control-solid {
    background-color: darken($secondary, 2);
    border-color: $secondary;
    @include placeholder($input-solid-placeholder-color);
    color: $white;
    transition: $transition-input;
    border-radius: 0 !important;
    & .react-calendar__month-view__weekdays__weekday {
      color: $black !important;
    }
    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: ($secondary);
      border-color: lighten($secondary, 5) !important;
      color: $white;
      transition: $transition-input;
      border-radius: 0 !important;
    }
  }

  // White style
  &.form-control-white {
    background-color: $body-bg;
    border-color: $body-bg;
    border-radius: 0 !important;
    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $body-bg;
      border-color: $body-bg;
    }
  }

  // Flush
  &.form-control-flush {
    @include input-reset();
  }
}

// Placeholder colors
.placeholder-gray-500 {
  @include placeholder($gray-500);
}

.placeholder-white {
  @include placeholder($white);
}

// Textarea reset resize
.resize-none {
  resize: none;
}
