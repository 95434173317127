@import '../../fonts';
.customer-portal-container {
  font-family: 'din-next-lta-arabic-regular';
  & button.customer-portal-nav-button {
    @extend .fs-4;
    @extend .ff-admm-bold;
  }

  .bg-primary-cp-dark {
    background-color: darken($primary, 20) !important;
  }

  .bg-secondary-cp-dark {
    background-color: darken($secondary, 17) !important;
  }

  .radius-bottom-3 {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .radius-bottom-0 {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .filter-cp-button {
    @extend .bg-primary-cp-dark;
    position: relative;
    transition: background-color 0.3s;
    &.active {
      height: 57px;
    }
    &.mobile-active {
      height: 50px;
    }
  }
  .filter-group-by-button {
    @extend .bg-primary-cp-dark;
    transition: background-color 0.3s;
    &:hover {
      background-color: darken($primary, 12) !important;
    }
  }

  .form-label {
    text-transform: uppercase !important;
  }
  .filters-box-cp {
    @extend .bg-primary-cp-dark;
    border-radius: 0px;
  }
}
