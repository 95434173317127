.pin-input {
  width: 17rem;
  & > .pin-input-row {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__display {
    @extend .form-control;
    @extend .form-control-solid;
    text-align: center;
    height: 3rem;
  }
}

.pin-input-row {
  & > button {
    font-size: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @extend .btn;
    @extend .btn-bg-light;
    height: 5rem;
    width: 5rem;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
  & > .pin-input__check {
    @extend .btn-primary;
  }
}

.pin-input__title {
  text-align: center;
  font-weight: 600;
  display: block;
  font-size: 1.5rem;
}
