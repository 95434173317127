.legends {
  position: absolute;
  top: 0;
  left: 0;

}

.root {
  position: relative;
}
