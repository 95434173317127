@import 'src/_metronic/assets/sass/style.dark.scss';

.root {
  position: relative;
}

.frontHorizontalSeparator {
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0.1);
  transform-origin: left;
  transition: transform 300ms ease;
}

.frontHorizontalSeparatorHovered {
  transform: scaleX(1);
}
