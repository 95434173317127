
.rs-rs-radio-wrapper {
    border: 1px solid $light !important;
  }
  .rs-radio-wrapper.active {
    border: 1px solid $primary !important;
  }

  .rs-radio-input-wrapper {
    background-color: transparent; 
    transition: all 0.3s ease;
  }
  .rs-radio-label {
    color: $wnt-gray-900;
    font-size: 1.075rem;
    font-weight: 600 !important;
  }
  .rs-radio-input-wrapper.active {
    border-color: $primary-light; 
    color: $primary;
  }
  
  .rs-radio-input-wrapper.active .form-check-label {
    color: $primary; 
  }

  .rs-radio-input-description  {
    color: $wnt-gray-900;
  }

  .rs-radio-input-wrapper.active .form-check-input {
    background-color: $primary; 
    flex-shrink: 0;

  }
  .rs-radio-input-wrapper .form-check-input {
    background-color: $wnt-gray-200;
    flex-shrink: 0;
    border-color: $secondary-light
  }