.size-input-item {
    @extend .mb-5;
  }
  
  .size-input-item-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0%;

    &__name-input {
        width: 100%;
    }
   
  }
  