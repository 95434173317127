.menu-product {
  all: unset;
  display: flex;
  width: 100%;
  height: 13rem;

  & .product-thumbnail {
    flex-shrink: 0;
    margin-right: $spacer;
    width: 40%;
    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & .description-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .description {
      flex-grow: 1;
      overflow-y: auto;
      margin: 0;
    }
  }
}

.menu-product-category {
  width: 100%;
}
